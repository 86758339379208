// Default sections
export const BANNER_SECTION = 'bannerSection';
export const ABOUT_SECTION = 'aboutSection';
export const EVENTS_SECTION = 'eventsSection';
export const EVENTS_SECTION_EMPTY = 'eventsSectionEmpty';
export const PLATFORM_SECTION_EMPTY = 'platformNameEmpty';
export const BENEFITS_SECTION = 'benefitSection';
export const COURSES_SECTION = 'coursesSection';
export const COURSES_SECTION_EMPTY = 'coursesSectionEmpty';
export const RESOURCES_SECTION = 'resourcesSection';
export const NFT_SECTION = 'nftSection';
export const RESOURCES_SECTION_EMPTY = 'resourcesSectionEmpty';
export const PAYMENT_SECTION = 'paymentSection';

// Custom sections
export const CARD_GRID_SECTION = 'cardGridSection';
export const TESTIMONIAL_SECTION = 'testimonialSection';

export const platformTypes = {
  discord: 'discord',
  facebook: 'facebook',
  whatsapp: 'whatsapp',
  telegram: 'telegram',
  slack: 'slack',
  linkedin: 'linkedin'
};

export const nftColors = [
  {
    color: 'rgba(240, 240, 255, 1)',
    textColor: 'rgba(52, 66, 178, 1)'
  },
  {
    color: '#E6F9FF',
    textColor: '#E6F9FF'
  },
  {
    color: '#FFF0E5',
    textColor: 'rgba(189, 75, 0, 1)'
  },
  {
    color: '#E6F9FF',
    textColor: 'rgba(238, 250, 219, 1)'
  },
  {
    color: 'rgba(254, 236, 247, 1)',
    textColor: 'rgba(205, 29, 141, 1)'
  }
];

export const staticProps = {
  profileImage:
    'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/community-profile-picture-placeholder.png',
  title: "Neil's Pilot Community",
  host: ' Neil Beukes',
  memberCount: '185',
  platformName: platformTypes.discord,
  memberProfileImages: [
    'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/placeholder-user-profile-image-1.png',
    'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/placeholder-user-profile-image-2.png',
    'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/placeholder-user-profile-image-3.png',
    'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/placeholder-user-profile-image-4.png'
  ],
  signupCtaLabel: 'Join now',
  about:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  liveEvents: [
    {
      time: '2022-08-05T11:44:45Z',
      hostName: 'Neil Beukes',
      title: 'Some Awesome Flying Event',
      hostProfileImage:
        'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/placeholder-user-profile-image-4.png'
    },
    {
      time: '2022-08-10T11:44:45Z',
      hostName: 'Neil Beukes',
      title: 'Some Awesome Floating Event',
      hostProfileImage:
        'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/placeholder-user-profile-image-4.png'
    },
    {
      time: '2022-08-18 06:45:00',
      hostName: 'Neil Beukes',
      title: 'Final Graduation',
      hostProfileImage:
        'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/placeholder-user-profile-image-4.png'
    },
    {
      time: '2022-08-18T11:44:45Z',
      hostName: 'Neil Beukes',
      title: 'Final Graduation',
      hostProfileImage:
        'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/placeholder-user-profile-image-4.png'
    },
    {
      time: '2022-08-18T11:44:45Z',
      hostName: 'Neil Beukes',
      title: 'Final Graduation',
      hostProfileImage:
        'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/placeholder-user-profile-image-4.png'
    }
  ],
  exclusiveContent: {
    classes: [
      {
        thumbnailImage:
          'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/exclusive_content_placeholder.png',
        title: 'How to fly',
        tags: ['Aviate'],
        videoCount: 7,
        externalLinkCount: 5
      },
      {
        thumbnailImage:
          'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/exclusive_content_placeholder.png',
        title: 'How to navigate on long trips regionally',
        tags: ['Aviate', 'Navigate', 'Focus'],
        videoCount: 7
      },
      {
        thumbnailImage:
          'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/exclusive_content_placeholder.png',
        title: 'How to Land',
        tags: ['Focus', 'Communicate'],
        videoCount: 7,
        externalLinkCount: 5
      },
      {
        thumbnailImage:
          'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/exclusive_content_placeholder.png',
        title: 'How to fly',
        tags: ['Aviate', 'Navigate'],
        videoCount: 7,
        externalLinkCount: 5
      }
    ],
    collections: [
      {
        icon: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/collection-icon-placeholder.png',
        title: 'Trading & Analytics',
        videoCount: 10,
        externalLinkCount: 2
      },
      {
        icon: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/collection-icon-placeholder.png',
        title: 'Trading & Analytics',
        videoCount: 10,
        externalLinkCount: 2
      },
      {
        icon: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/collection-icon-placeholder.png',
        title: 'Trading & Analytics',
        videoCount: 10,
        externalLinkCount: 2
      },
      {
        icon: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/collection-icon-placeholder.png',
        title: 'Trading & Analytics',
        videoCount: 10,
        externalLinkCount: 2
      },
      {
        icon: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/collection-icon-placeholder.png',
        title: 'Trading & Analytics',
        videoCount: 10,
        externalLinkCount: 2
      },
      {
        icon: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/collection-icon-placeholder.png',
        title: 'Trading & Analytics',
        videoCount: 10,
        externalLinkCount: 2
      },
      {
        icon: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/collection-icon-placeholder.png',
        title: 'Trading & Analytics',
        videoCount: 10,
        externalLinkCount: 2
      }
    ]
  }
  //priceInfo: {isFree, hasMultiplePricingPlans, plans: [{title/id: "x months", amount, currency}, {title/id: "x+5 months", amount, currency, savedPercentFromBasePlan},...] } // this should only be sent for web app, mobile FE fetches on its own, plan to be singleton if hasMultiplePricingPlans is false
  // benefits: [] <optional, only for nas original community>,
};

{
  /* firstName: 'John',
  lastName: 'Doe',
  profileImageProps: {
    alt: 'profile image',
    desktopImgProps: {
      src: 'https://d2yjtdaqamc55g.cloudfront.net/randomProfileImage5.jpg',
      width: 40,
      height: 40
    },
    mobileImgProps: {
      src: 'https://d2yjtdaqamc55g.cloudfront.net/randomProfileImage5.jpg',
      width: 40,
      height: 40
    }
  } */
}

// AVAILABLE SECTION KEYS
export const OG_META_TAGS = 'openGraphPageMetadata';
export const DESCRIPTION_SECTION = 'descriptionSection';
export const WHY_JOIN_SECTION = 'whyJoinSection';
export const MEMBERSHIP_BENEFITS_SECTION = 'membershipBenefitsSection';
export const COMMUNITY_MEMBERS_SECTION = 'communityMembersSection';
export const COMMUNITY_MEMBERS_SECTION_V2 = 'communityMembersSectionV2';
export const COMMUNITY_EVENTS_SECTION = 'communityEventsSection';
export const WHO_IS_THIS_FOR_SECTION = 'whoIsThisForSection';
export const WHO_IS_THIS_FOR_DARK_SECTION = 'whoIsThisForDarkSection';
export const PRICING_SECTION = 'pricingSection';
export const FAQ_SECTION = 'faq';
export const SUCCESS_TEXT_AFTER_WAITLIST =
  "You've been added to the waitlist!";

export const JOIN_WAITLIST_MODAL_STATIC_PROPS =
  'joinWaitlistModalStaticProps';
export const GO_TO_PORTAL = 'Go to Portal';
export const ACCESS_YOUR_COMMUNITY = 'Go To Member Portal';
export const ON_WAITLIST = 'On Waitlist';
export const JOIN_WAITLIST = 'Join Waitlist';
export const JOIN_CTA_LABEL = 'Join Now';
export const JOIN_FREE_CTA_LABEL = 'Join for Free';
// TEMP static props (until CMS is set up)
export const communityCode = 'test-community';

// session Storage keys
export const DISCORD_UUID_KEY = 'discordUuid';
export const COUPON_CODE_KEY = 'couponCode';

const MOCK_REGEN_IMAGE = {
  alt: 'John S.',
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/ssr-academy/ritesh/cohort/png/ritesh-billion-dollar-testimonial-2.png',
    layout: 'responsive',
    width: 48,
    height: 48
  }
};

export const testimonialStaticProps = {
  testimonialsDesktopProps: {
    leftCardProps: {
      text: [
        {
          text: '“An expert community builder who deeply understands how to get people together and give a project value.”'
        }
      ],
      studentData: {
        name: 'Diego Borgo',
        imgUrl:
          'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/nasweb3community/testimonial-student-1.png',
        infoText: 'NFT Strategist for Fortune 500 Brands'
      }
    },
    rightCardProps: {
      text: [
        {
          text: '“So many NFT founders have benefited by learning from the most respected thought leaders in this new space.”'
        }
      ],
      studentData: {
        name: 'Mai Akiyoshi',
        imgUrl:
          'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/nasweb3community/testimonial-student-3.png',
        infoText: 'Co-founder of Curious Addys'
      }
    },
    centerCardProps: {
      text: [
        {
          text: `“The classes are worth thousands of dollars and it's the most comprehensive crypto education out there!”`
        }
      ],
      studentData: {
        name: 'Nuseir Yassin',
        imgUrl:
          'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/nasweb3community/testimonial-student-2.png',
        infoText: 'FOUNDER OF NAS DAILY'
      }
    }
  },
  testimonialsCaourselV2Props: {
    testimonials: [
      {
        text: [
          {
            text: '“An expert community builder who deeply understands how to get people together and give a project value.”'
          }
        ],
        studentData: {
          name: 'Diego Borgo',
          imgUrl:
            'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/nasweb3community/testimonial-student-1.png',
          infoText: 'NFT Strategist for Fortune 500 Brands'
        }
      },
      {
        text: [
          {
            text: `“The classes are worth thousands of dollars and it's the most comprehensive crypto education out there!”`
          }
        ],
        studentData: {
          name: 'Nuseir Yassin',
          imgUrl:
            'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/nasweb3community/testimonial-student-2.png',
          infoText: 'FOUNDER OF NAS DAILY'
        }
      },
      {
        text: [
          {
            text: '“So many NFT founders have benefited by learning from the most respected thought leaders in this new space.”'
          }
        ],
        studentData: {
          name: 'Mai Akiyoshi',
          imgUrl:
            'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/nasweb3community/testimonial-student-3.png',
          infoText: 'Co-founder of Curious Addys'
        }
      }
    ]
  }
};

export const defaultBannerImg =
  'https://s3.ap-southeast-1.amazonaws.com/image-assets.nasdaily.com/na-website/community-product-page/nas-content-community/community-hero-banner-img.png';

export const whatYoullGetCardsStaticProps = {
  sectionTitle: [
    { text: 'What you’ll get in the' },
    { text: 'Web3 Community', isNewLine: true }
  ],
  cards: [
    {
      title: 'Somethign1',
      description: 'Something1 short descriptuiobs',
      imageUrl:
        'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/what-youll-get-test-image.png'
    },
    {
      title: 'Somethign1',
      description: 'Something1 short descriptuiobs',
      imageUrl:
        'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/what-youll-get-test-image.png'
    },
    {
      title: 'Somethign3',
      description: 'Something1 short descriptuiobs',
      imageUrl:
        'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/what-youll-get-test-image.png'
    },
    {
      title: 'Something comeplete pther wordly',
      description: 'Something1 shorsssss t descriptuiobs',
      imageUrl:
        'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/what-youll-get-test-image.png'
    }
  ]
};

// leaving this for the upcoming updates reference
// const MEMBERS_ONLY_DATA_ACCESS = 'MEMBERS_ONLY';
// const PUBLIC_DATA_ACCESS = 'PUBLIC';

// const MEMBERS_ONLY_UPCOMING_EVENT_ACCESS_LABEL = 'Community Members only';
// const MEMBERS_ONLY_PAST_EVENT_ACCESS_LABEL =
//   'Recording available for members';

// const MOCK_UPCOMING_EVENT_DATA = {
//   datetime: '10-10-2022',
//   title: 'Test Event',
//   hosts: [
//     {
//       name: 'Regen Host',
//       image: MOCK_REGEN_IMAGE
//     }
//   ],
//   accessLevel: MEMBERS_ONLY_DATA_ACCESS,
//   accessLabel: MEMBERS_ONLY_UPCOMING_EVENT_ACCESS_LABEL
// };

// const MOCK_PAST_EVENT_DATA = {
//   datetime: '10-10-2022',
//   title: 'Test Event',
//   hosts: [
//     {
//       name: 'Regen Host',
//       image: MOCK_REGEN_IMAGE
//     }
//   ],
//   accessLevel: MEMBERS_ONLY_DATA_ACCESS,
//   preview: {
//     label: 'Past video preview',
//     videoSrc: {
//       dash: 'https://d1b6ra2xfc58va.cloudfront.net/f6910ad5-2267-4e34-95e2-22e304448b20/dash/Unstoppable_Confidence.mpd',
//       hls: 'https://d1b6ra2xfc58va.cloudfront.net/f6910ad5-2267-4e34-95e2-22e304448b20/hls/Unstoppable_Confidence.m3u8'
//     }
//   }
// };

export const MOCK_BE_DATA = {
  NAS_ACADEMY_CRYPTO_COMMUNITY: {
    metadata: {
      membersCount: 100,
      countriesCount: 30,
      pricePerMonth: 'USD 29',
      communityManager: {
        name: 'John',
        image: MOCK_REGEN_IMAGE
      }
    },
    // eventsSection: {
    //   pastEvents: [MOCK_PAST_EVENT_DATA, MOCK_PAST_EVENT_DATA],
    //   upcomingEvents: [MOCK_UPCOMING_EVENT_DATA, MOCK_UPCOMING_EVENT_DATA]
    // },
    pricingSection: {
      paymentFrequencyOptions: [
        {
          label: 'Monthly',
          discount: '30%',
          price: 'USD 1'
        },
        {
          label: 'Yearly',
          discount: '20%',
          price: 'USD 252'
        }
      ]
    }
  },
  NAS_ACADEMY_CONTENT_COMMUNITY: {
    metadata: {
      membersCount: 100,
      countriesCount: 30,
      pricePerMonth: 'USD 9',
      communityManager: {
        name: 'John',
        image: MOCK_REGEN_IMAGE
      }
    },
    // eventsSection: {
    //   pastEvents: [MOCK_PAST_EVENT_DATA, MOCK_PAST_EVENT_DATA],
    //   upcomingEvents: [MOCK_UPCOMING_EVENT_DATA, MOCK_UPCOMING_EVENT_DATA]
    // },
    pricingSection: {
      paymentFrequencyOptions: [
        {
          label: 'Monthly',
          discount: '30%',
          price: 'USD 1'
        },
        {
          label: 'Yearly',
          discount: '20%',
          price: 'USD 252'
        }
      ]
    }
  }
};

export const getEmptySectionImage = (url) => {
  return {
    alt: 'platform image',
    desktopImgProps: {
      src: url,
      width: 340,
      height: 227
    },
    mobileImgProps: {
      src: url,
      width: 340,
      height: 227
    }
  };
};

export const LiveEventsEmptyCardProps = {
  title: 'Create your first meet up',
  subtitle:
    'Events are one of the best way to boost the engagement of your community members!',
  ctaText: 'Create event',
  image: getEmptySectionImage(
    'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/emptystate-events.png'
  )
};

export const CoursesEmptyCardProps = {
  title: 'Share free or paid content with your community members',
  subtitle:
    'Here is where you can start selling digital products, ranging from small items like PDFs and links, to more complex ones like courses and ebooks. An effortless and streamlined platform to start making money!',
  ctaText: 'Create',
  image: getEmptySectionImage(
    'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/emptystate-course.png'
  )
};

export const DescriptionEmptyCardProps = {
  title: 'Add a description',
  subtitle:
    'Write a short and sweet description about your community in 3-4 sentences. It should tell your community members about your community and what it offers. Try to include information like: What is the theme of your community? What are your goals for your community? Who is this community for? Does it include any extras, like courses, resources, live events, or videos?',
  ctaText: 'Add description'
};

export const ResourcesEmptyCardProps = {
  title: 'Create your first resource collection',
  subtitle: 'You can share files, videos and links in a collection.',
  ctaText: 'Create collection',
  image: getEmptySectionImage(
    'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/emptystate-resource.png'
  )
};

export const PlatformEmptyCardProps = {
  title: 'Connect a chat platform',
  subtitle:
    'Your members need a place to interact and connect with each other!',
  ctaText: 'Connect chat',
  image: getEmptySectionImage(
    'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-landing-page/png/emptystate-connect-chat.png'
  )
};
