import Discord from '@/components/common/IconsV2/Discord';
import Facebook from '@/components/common/IconsV2/Facebook';
import LinkedInIcon from '@/components/common/IconsV2/LinkedInIcon';
import Slack from '@/components/common/IconsV2/Slack';
import Telegram from '@/components/common/IconsV2/Telegram';
import Whatsapp from '@/components/common/IconsV2/Whatsapp';

const {
  platformTypes,
  EVENTS_SECTION,
  COURSES_SECTION,
  BENEFITS_SECTION
} = require('./constants');

export const getPortalUrl = (middlePath, communityId) => {
  return `/portal/${middlePath}?activeCommunityId=${communityId}`;
};

export const getPlatformDetails = (platform) => {
  switch (platform) {
    case platformTypes.discord:
      return {
        displayName: 'Discord',
        getIcon: (props) => <Discord {...props} />,
        overviewText: 'Discord',
        getJoinText: (communityName) =>
          `Join ${communityName} to get access to the Discord server`
      };
    case platformTypes.whatsapp:
      return {
        displayName: 'WhatsApp',
        getIcon: (props) => <Whatsapp {...props} />,
        overviewText: 'WhatsApp',
        getJoinText: (communityName) =>
          `Join ${communityName} to get access to the WhatsApp group`
      };
    case platformTypes.telegram:
      return {
        displayName: 'Telegram',
        getIcon: (props) => <Telegram {...props} />,
        overviewText: 'Telegram',
        getJoinText: (communityName) =>
          `Join ${communityName} to get access to the Telegram group`
      };
    case platformTypes.facebook:
      return {
        displayName: 'Facebook',
        getIcon: (props) => <Facebook {...props} />,
        overviewText: 'Facebook',
        getJoinText: (communityName) =>
          `Join ${communityName} to get access to the Facebook group`
      };
    case platformTypes.slack:
      return {
        displayName: 'Slack',
        getIcon: (props) => <Slack {...props} />,
        overviewText: 'Slack',
        getJoinText: (communityName) =>
          `Join ${communityName} to get access to the Slack workspace`
      };
    case platformTypes.linkedin:
      return {
        displayName: 'LinkedIn',
        getIcon: (props) => <LinkedInIcon {...props} />,
        overviewText: 'LinkedIn',
        getJoinText: (communityName) =>
          `Join ${communityName} to get access to the LinkedIn group`
      };
    default:
      return null;
  }
};

// Map where the sections array comes from to check in a loop if there is data to show
export const sectionToResponseArrayMapping = {
  [EVENTS_SECTION]: ['upcomingEvents', 'pastEvents'],
  [COURSES_SECTION]: ['classes', 'collections'],
  [BENEFITS_SECTION]: 'benefits'
};

export const getPlatformImgProps = (url) => {
  return {
    alt: 'platform image',
    desktopImgProps: {
      src: url,
      width: 48,
      height: 48
    },
    mobileImgProps: {
      src: url,
      width: 48,
      height: 48
    }
  };
};

export const getUserImgProps = (url) => {
  return {
    alt: 'user profile image',
    desktopImgProps: {
      src: url,
      width: 40,
      height: 40
    },
    mobileImgProps: {
      src: url,
      width: 40,
      height: 40
    }
  };
};

export const getCommunityProfileImgProps = (url) => {
  return {
    alt: 'community profile image',
    desktopImgProps: {
      src: url,
      layout: 'fill'
    },
    mobileImgProps: {
      src: url,
      layout: 'fill'
    }
  };
};

export const getHostProfileImageData = (url) => {
  return {
    alt: 'Host profile image',
    desktopImgProps: {
      src: url,
      layout: 'fill'
    },
    mobileImgProps: {
      src: url,
      layout: 'fill'
    }
  };
};

const defaultEventImage =
  'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/common/png/default-event-banner-image.png';

export const getCommunityBannerImageLiveEvents = (url) => {
  let imageUrl = url || defaultEventImage;
  return {
    alt: 'community banner image',
    desktopImgProps: {
      src: imageUrl,
      layout: 'fill',
      objectFit: 'cover'
    },
    mobileImgProps: {
      src: imageUrl,
      layout: 'fill',
      objectFit: 'cover'
    }
  };
};

export const getCourseBannerImgData = (url) => {
  return {
    alt: 'Course banner image',
    desktopImgProps: {
      src: url,
      layout: 'fill',
      objectFit: 'cover'
    },
    mobileImgProps: {
      src: url,
      layout: 'fill',
      objectFit: 'cover'
    }
  };
};

export const getCommunityOverviewImageProps = (url) => {
  return {
    alt: 'community overview Image',
    desktopImgProps: {
      src: url,
      layout: 'fill',
      objectFit: 'cover'
    },
    mobileImgProps: {
      src: url,
      layout: 'fill',
      objectFit: 'cover',
      objectPosition: 'center'
    }
  };
};

export const getLiveEventHostProfileImageProps = (url) => {
  return {
    alt: 'Live Event profile image',
    mobileImgProps: {
      src: url,
      width: 32,
      height: 32
    }
  };
};

export const getExclusiveContentImage = (url) => {
  return {
    alt: 'Exclusive Content Image',
    mobileImgProps: {
      src: url,
      layout: 'fill'
    },
    desktopImgProps: {
      src: url,
      layout: 'fill'
    }
  };
};

export const textAndImageCardImageImage = (url) => {
  return {
    alt: `TextAndImageCardImage card`,
    mobileImgProps: {
      src: url,
      width: 366,
      height: 244
    },
    desktopImgProps: {
      src: url,
      width: 360,
      height: 240
    }
  };
};

export const getTestimonialImage = (url) => {
  return {
    alt: 'Testimonial Image',
    mobileImgProps: {
      src: url,
      width: 48,
      height: 48,
      layout: 'responsive'
    },
    desktopImgProps: {
      src: url,
      width: 48,
      height: 48,
      layout: 'responsive'
    }
  };
};

export const getExclusiveContentColectionIconImage = (url) => {
  return {
    alt: 'Exclusive Content Icon Image',
    mobileImgProps: {
      src: url,
      width: 32,
      height: 40
    }
  };
};

export function getFirstLetters(string) {
  const firstLetters = string
    ?.split(' ')
    .map((word) => word[0])
    .join('');

  return firstLetters;
}

export const getHighestIntervalPricing = (pricingData) => {
  if (!pricingData) return null;
  return pricingData?.reduce((acc, curr) => {
    if (curr?.recurring?.interval === acc?.recurring?.interval) {
      // If the intervals are the same, we want to return the one with the highest interval count
      return curr?.recurring?.interval_count >
        acc?.recurring?.interval_count
        ? curr
        : acc;
    }
    // If the intervals are different we want to return the one with the highest interval
    if (curr?.recurring?.interval !== acc?.recurring?.interval) {
      if (
        curr?.recurring?.interval === 'year' &&
        acc?.recurring?.interval === 'month'
      ) {
        return curr;
      }
      if (
        curr?.recurring?.interval === 'month' &&
        acc?.recurring?.interval === 'year'
      ) {
        return acc;
      }

      return curr?.recurring?.interval === 'year' ? curr : acc;
    }
  }, pricingData?.[0]);
};

export const getLowestIntervalPricing = (pricingData) => {
  if (!pricingData) return null;
  return pricingData?.reduce((acc, curr) => {
    if (curr?.recurring?.interval === acc?.recurring?.interval) {
      // If the intervals are the same, we want to return the one with the lowest interval count
      return curr?.recurring?.interval_count <
        acc?.recurring?.interval_count
        ? curr
        : acc;
    }
    // If the intervals are different we want to return the one with the lowest interval
    if (curr?.recurring?.interval !== acc?.recurring?.interval) {
      if (
        curr?.recurring?.interval === 'year' &&
        acc?.recurring?.interval === 'month'
      ) {
        return acc;
      }
      if (
        curr?.recurring?.interval === 'month' &&
        acc?.recurring?.interval === 'year'
      ) {
        return curr;
      }

      return curr?.recurring?.interval === 'year' ? acc : curr;
    }
  }, pricingData?.[0]);
};
