import React from 'react';

const className = 'c-IconsV2-Slack';

const Slack = ({ customClassNames }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      className={`${className} ${customClassNames}`}>
      <path
        d="M12.9837 29.0131C12.9837 31.2101 11.1889 33.0049 8.99184 33.0049C6.79478 33.0049 5 31.2101 5 29.0131C5 26.816 6.79478 25.0212 8.99184 25.0212H12.9837V29.0131Z"
        fill="#E01E5A"
      />
      <path
        d="M14.9951 29.0131C14.9951 26.816 16.7899 25.0212 18.987 25.0212C21.184 25.0212 22.9788 26.816 22.9788 29.0131V39.0081C22.9788 41.2052 21.184 43 18.987 43C16.7899 43 14.9951 41.2052 14.9951 39.0081V29.0131Z"
        fill="#E01E5A"
      />
      <path
        d="M18.987 12.9839C16.7899 12.9839 14.9951 11.1891 14.9951 8.99208C14.9951 6.79502 16.7899 5.00024 18.987 5.00024C21.184 5.00024 22.9788 6.79502 22.9788 8.99208V12.9839H18.987Z"
        fill="#36C5F0"
      />
      <path
        d="M18.9869 14.9954C21.184 14.9954 22.9787 16.7901 22.9787 18.9872C22.9787 21.1843 21.184 22.979 18.9869 22.979H8.99184C6.79478 22.979 5 21.1843 5 18.9872C5 16.7901 6.79478 14.9954 8.99184 14.9954H18.9869Z"
        fill="#36C5F0"
      />
      <path
        d="M35.0161 18.9872C35.0161 16.7901 36.8109 14.9954 39.0079 14.9954C41.205 14.9954 42.9998 16.7901 42.9998 18.9872C42.9998 21.1843 41.205 22.979 39.0079 22.979H35.0161V18.9872Z"
        fill="#2EB67D"
      />
      <path
        d="M33.0052 18.9871C33.0052 21.1842 31.2104 22.979 29.0133 22.979C26.8163 22.979 25.0215 21.1842 25.0215 18.9871V8.99208C25.0215 6.79502 26.8163 5.00024 29.0133 5.00024C31.2104 5.00024 33.0052 6.79502 33.0052 8.99208V18.9871Z"
        fill="#2EB67D"
      />
      <path
        d="M29.0133 35.0164C31.2104 35.0164 33.0052 36.8111 33.0052 39.0082C33.0052 41.2053 31.2104 43 29.0133 43C26.8163 43 25.0215 41.2053 25.0215 39.0082V35.0164H29.0133Z"
        fill="#ECB22E"
      />
      <path
        d="M29.0133 33.0049C26.8163 33.0049 25.0215 31.2101 25.0215 29.0131C25.0215 26.816 26.8163 25.0212 29.0133 25.0212H39.0084C41.2054 25.0212 43.0002 26.816 43.0002 29.0131C43.0002 31.2101 41.2054 33.0049 39.0084 33.0049H29.0133Z"
        fill="#ECB22E"
      />
    </svg>
  );
};

Slack.defaultProps = {
  customClassNames: ''
};

export default Slack;
