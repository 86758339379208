import React from 'react';

const className = 'c-IconsV2-Whatsapp';

const Whatsapp = ({ customClassNames, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      className={`${className} ${customClassNames}`}>
      <path
        d="M3.98535 44.1057L6.81287 33.7803C5.06874 30.7583 4.15134 27.3303 4.15262 23.8183C4.1571 12.8316 13.0979 3.89307 24.0843 3.89307C29.4157 3.89531 34.4199 5.97044 38.1831 9.73683C41.946 13.5032 44.0176 18.5097 44.0156 23.8344C44.0108 34.8208 35.0688 43.7606 24.0843 43.7606C24.0836 43.7606 24.0846 43.7606 24.0843 43.7606H24.0756C20.7399 43.7593 17.4625 42.9226 14.5514 41.3349L3.98535 44.1057ZM15.0407 37.7278L15.646 38.0863C18.1889 39.5956 21.1046 40.3938 24.0779 40.3954H24.0843C33.2148 40.3954 40.6463 32.9651 40.6501 23.8331C40.6517 19.4075 38.9303 15.2461 35.8026 12.1157C32.6745 8.98541 28.5156 7.26051 24.0904 7.25859C14.9525 7.25859 7.52135 14.6879 7.51782 23.8196C7.51654 26.949 8.39197 29.9963 10.0505 32.6335L10.4447 33.2603L8.77072 39.3716L15.0407 37.7278Z"
        fill="white"
      />
      <path
        d="M4.67432 43.41L7.4041 33.4416C5.71989 30.5244 4.83453 27.2146 4.83549 23.8247C4.83966 13.2183 13.4712 4.58936 24.0766 4.58936C29.2241 4.5916 34.0553 6.59496 37.6874 10.2309C41.3205 13.8669 43.32 18.7 43.3181 23.8398C43.3136 34.4465 34.6817 43.0764 24.0776 43.0764C24.0766 43.0764 24.0779 43.0764 24.0776 43.0764H24.0689C20.8489 43.0751 17.6846 42.267 14.8744 40.7353L4.67432 43.41Z"
        fill="#25D366"
      />
      <path
        d="M3.98047 44.1064L6.80798 33.7811C5.06386 30.759 4.14645 27.331 4.14774 23.8191C4.15222 12.8323 13.093 3.8938 24.0794 3.8938C29.4108 3.89604 34.415 5.97118 38.1782 9.73756C41.9411 13.504 44.0127 18.5104 44.0108 23.8351C44.006 34.8215 35.0639 43.7613 24.0794 43.7613C24.0788 43.7613 24.0797 43.7613 24.0794 43.7613H24.0708C20.735 43.76 17.4576 42.9234 14.5465 41.3356L3.98047 44.1064ZM15.0358 37.7285L15.6411 38.0871C18.1841 39.5963 21.0997 40.3945 24.073 40.3961H24.0794C33.2099 40.3961 40.6414 32.9659 40.6452 23.8338C40.6468 19.4083 38.9255 15.2468 35.7977 12.1165C32.6696 8.98615 28.5107 7.26125 24.0855 7.25932C14.9477 7.25932 7.51646 14.6886 7.51294 23.8203C7.51166 26.9497 8.38708 29.997 10.0457 32.6342L10.4398 33.261L8.76584 39.3723L15.0358 37.7285Z"
        fill="#25D366"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0938 15.4884C18.7208 14.6592 18.3279 14.6425 17.9729 14.6281C17.6826 14.6156 17.3503 14.6162 17.0186 14.6162C16.6867 14.6162 16.147 14.7412 15.6908 15.2395C15.2341 15.7377 13.9473 16.9426 13.9473 19.3929C13.9473 21.8436 15.7321 24.2113 15.9811 24.5439C16.23 24.8762 19.4264 30.0653 24.4883 32.0616C28.6953 33.7208 29.5511 33.3908 30.4644 33.3078C31.3776 33.2248 33.4108 32.103 33.8257 30.9401C34.241 29.7773 34.241 28.7807 34.1164 28.5721C33.9917 28.3648 33.6598 28.2401 33.1618 27.9912C32.6638 27.7422 30.2154 26.537 29.7588 26.371C29.3022 26.2047 28.9702 26.1221 28.6382 26.6207C28.3059 27.1186 27.3523 28.2401 27.0617 28.5721C26.7711 28.905 26.4804 28.9467 25.9825 28.6974C25.4845 28.4478 23.8804 27.9223 21.9777 26.2256C20.4973 24.9057 19.4975 23.2753 19.2069 22.7767C18.9166 22.2788 19.2021 22.0327 19.4257 21.7606C19.8285 21.2707 20.5043 20.3898 20.6703 20.0578C20.8363 19.7252 20.7533 19.4346 20.629 19.1853C20.5043 18.9363 19.5363 16.4735 19.0938 15.4884Z"
        fill="white"
      />
    </svg>
  );
};

Whatsapp.defaultProps = {
  customClassNames: '',
  width: 48,
  height: 48
};

export default Whatsapp;
